<template>
  <div class="bg-white rounded-10 p-10 pt-20 pb-32 text-left flex justify-center">
    <div class="w-1/2">
      <div class="text-c12">
        Type de remise *
      </div>
      <multiselect
          background="#fff"
          value-t="Selectionner"
          border="1px solid #DCDCE4"
          class="mt-1"
          :option="optionType"
          @info="retourType"
      />

      <div class="text-c12 mt-6">
        Numero de remise *
      </div>
      <inputo class="mt-1" @info="retourNumero"/>

      <div class="text-c12 mt-6">
        Date de remise *
      </div>

      <datepicker placeholder="Cliquez ici" class="w-full mt-1" mode="dateTime" @info="retourDate"/>

      <div class="text-c12 mt-6">
        Compte bancaire de destination *
      </div>
      <multiselect
          v-if="optionCompte.length > 0"
          background="#fff"
          value-t="Selectionner"
          border="1px solid #DCDCE4"
          class="mt-1 mb-20"
          :option="optionCompte"
          @info="retourCompte"
      />

    </div>

  </div>
</template>

<script>
import inputo from '../../helper/form/input'
import multiselect from '../../helper/form/multiselect'
import datepicker from '../../helper/form/datePicker'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    inputo,
    multiselect,
    datepicker
  },
  props: {
  },

  data () {
    return {
      optionType: ['Chèque', 'Espèce'],
      optionCompte: [],
      response: {},
      allData: []
    }
  },

  created () {
    this.allBanque()
  },

  methods: {
    retourType (answer) {
      if (answer !== 'Selectionner') {
        this.response.type = answer
        this.sender()
      }
    },

    retourCompte (answer) {
      if (answer !== 'Selectionner') {
        this.response.compte = answer
        for (let item in this.allData) {
          if (this.allData[item].bankName === answer) {
            this.response.bank = this.allData[item].id
          }
        }
        this.sender()
      }
    },

    retourDate (answer) {
      this.response.date = answer
      this.sender()
    },

    retourNumero (answer) {
      this.response.numero = answer
      this.sender()
    },

    sender () {
      if (this.response.type && this.response.compte && this.response.date && this.response.numero) {
        this.$emit('info', this.response)
      }
    },

    allBanque () {
      http.get(apiroutes.baseURL + apiroutes.allBank)
          .then(response => {
            console.log(response)
            this.allData = response.banks
            const data = response.banks
            let tab = []
            for (let item in data) {
              tab.push(data[item].bankName)
            }
            this.optionCompte = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
.inputo {
  background: #F5F5F5;
  border: 0.956973px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4.78486px;
  height: 36.36px;
}
</style>
