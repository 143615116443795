<template>
      <div class="flex">
        <div class="w-1/5 text-c18 flex items-center">
          <radio @info="retourSelect"/>
          <div class="ml-4">
            <span v-if="donnes.meta">
              {{ donnes.meta.reference }}
            </span>
            <span v-if="!donnes.meta">
              -
            </span>
          </div>
        </div>
        <div class="w-1/5 ml-6 flex items-center inputo h-40 pl-4 pr-4">
         <span v-if="donnes.meta">
           {{ donnes.meta.banque }}
         </span>
          <span v-if="!donnes.meta">
            -
          </span>
        </div>
        <div class="w-1/5 ml-6 flex items-center inputo h-40 pl-4 pr-4">
            {{ donnes.amount.toLocaleString() }}
        </div>
        <div class="w-1/5 ml-6 flex items-center inputo h-40 pl-4 pr-4">
          <span v-if="donnes.meta">
            {{ donnes.meta.porteur }}
          </span>
          <span v-if="!donnes.meta">
            -
          </span>
        </div>
        <div class="w-1/5 ml-6 flex items-center text-90">
          <span v-if="donnes.created_at">
            {{ new Date(donnes.created_at).toLocaleDateString() }}
          </span>

        </div>
      </div>
</template>

<script>
import radio from '../../helper/form/radio'

export default {
  name: "Index",
  components: {
    radio,
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      temoin: false
    }
  },
  methods: {
    retourSelect (answer) {
      if (answer !== this.temoin) {
        this.temoin = answer
        let obj = {
          active: answer,
          index: this.index
        }
        this.$emit('info', obj)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.bloc2 {
  border-left: 0.352891px solid #DDDDDD;
}
.inputo {
  background: #F5F5F5;
  border: 0.956973px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4.78486px;
  height: 36.36px;
}
</style>
